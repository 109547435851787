import domReady from '@wordpress/dom-ready';

domReady(() => {
  function moveImg() {
    const AboutImg = document.querySelector('.about-block__image');
    const block = document.querySelector('.about-block-block');
    const titleInner = document.querySelector('.about-block__title-inner');
    if (AboutImg) {
      if (window.innerWidth < 768) {
        titleInner.appendChild(AboutImg);
      } else {
        block.appendChild(AboutImg);
      }
    }
  }

  moveImg();

  window.addEventListener('resize', moveImg);
});
